@import 'colors.scss';

.loaderDots {
  width: 150px;
  position: relative;
  margin: auto;
  top: 50%;

  & circle {
    fill: var(--dig-color__primary__base);
    fill-opacity: 0.2;
    transition: ease 0.5s;
  }
  & .currentDot {
    fill-opacity: 1;
  }

  & .message {
    position: relative;
    top: 15px;
  }
}
