.title {
  margin-top: 0;
}

.mb1 {
  margin-bottom: var(--spacing__unit--1);
}

.marginBottom {
  margin-bottom: var(--spacing__unit--3);
}

.illustrationContainer {
  height: 200px;
  width: 200px;
  margin-bottom: var(--spacing__unit--5);
}

.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
