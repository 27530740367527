.hyperlink {
  cursor: pointer;
  text-align: left;
  background: #ffffff;
  text-decoration: underline;
  color: #2b72d9;
  position: relative;
  white-space: pre;
}
.hyperlink:hover {
  color: #1c3a78;
  cursor: pointer;
}

.hyperlink-textarea {
  cursor: auto;
  background: white !important;
}
.hyperlink-textarea:focus {
  background: white !important;
}

@media print {
  .hyperlink {
    background: none;
  }
}
