@import 'colors.scss';

.m-signer-mobile-header-alert {
  width: 100%;
  position: absolute;
  z-index: 2047;
  padding: 7px 25px;
  font-size: 14px;
  line-height: 1.2em;
  background-color: $plume;
  border-bottom: 1px solid $warm-chinchilla;
  color: $white;
  font-weight: 400;
  text-align: center;

  .m-signer-mobile-header-alert-icon {
    height: 20px;
    display: inline;
    margin-right: 6px;
    font-size: 16px;
    position: relative;
    top: 2px;
    color: $white;

    .error {
      top: 3px;
      background-image: url(./alert-icon.svg);
      background-repeat: no-repeat;
      width: 17px;
      height: 17px;
    }
  }

  .m-signer-mobile-header-alert-message {
    display: inline;
    line-height: 21px;
  }

  .m-signer-mobile-header-alert-close {
    position: absolute;
    top: 11px;
    right: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &.error {
    background-color: $blood;
  }

  &.success {
    background-color: $grasshopper;
  }

  &.info {
    background-color: $goldenrod;
  }

  &.instructions {
    background-color: $ashen;
  }
}
