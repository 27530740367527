.hidden,
.placeholder {
  white-space: pre;
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.p {
  margin: 0;
  padding-right: 0.5em;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  overflow: hidden;
}
