@import 'colors.scss';

.m-signer-mobile-template-link--login-or-signup {
  width: 100%; // Of parent container
  height: 300px;
  border: 1px solid $warm-chinchilla;

  .m-signer-mobile-template-link--login-or-signup--email {
    width: 80%;
    margin: 15px 0;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
  }

  .m-signer-mobile-template-link--login-or-signup--error {
    color: $red;
    margin-top: 8px;
  }

  .m-signer-mobile-template-link--login-or-signup--tos {
    padding: 20px;
    padding-top: 10px;
  }
}
