.document {
  position: relative;
  /* height is being managed by the parent (.dropTarget) in page-container */
  width: 100%;

  overflow: auto;
  user-select: none;
  background-color: var(--canvasColor, #e9ecef);

  /* Allow access past the end of the document so that Safari users can reach
  everything even if the toolbar doesn't disappear when scrolling. */
  padding-bottom: 50vh;
}

body.blockScroll {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.pageContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noBackground {
  background: none;
}

@media print {
  .document {
    background-color: transparent !important;
    -webkit-print-color-adjust: exact !important;
  }
}
