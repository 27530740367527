@import 'colors';

// TODO
// There's a lot of shared styles between this and confirm-decline modal but
// also some differences.  We should refactor the CSS here to try and merge the
// two into a shared CSS rule.
.m-modal-confirm-delegate {
  min-height: 379px;

  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 12px;
    height: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  .loader {
    position: relative;
    width: 25%;
    top: 200px;
    left: 38%;
  }

  .main-content {
    padding: 30px 75px 20px 75px;

    h1,
    h2 {
      font-weight: var(--type__body__standard--fontweight_strong);
      text-transform: uppercase;
    }

    h1 {
      font-size: 18px;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 0;

      .asterisk {
        margin-left: 2px;
        color: $red;
      }
    }

    small {
      font-size: 12px;
      word-spacing: 1px;
      color: $grey;
    }

    p {
      max-width: 425px;
      margin: 10px 0 20px 0;
    }

    textarea {
      height: 100px;
      margin-top: 10px;
      resize: none;
    }

    input {
      width: 100%;
    }

    .error {
      color: $red;
      font-size: 14px;
    }
  }

  .cta-bar {
    min-height: 65px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px 25px;

    .cta-bar__message,
    .cta-bar__actions {
      display: inline-block;
      vertical-align: middle;
    }

    button,
    .cancel-link {
      display: inline-block;
      vertical-align: middle;
    }

    button {
      font-size: 14px;
      font-weight: var(--type__body__standard--fontweight_strong);
    }

    .cta-bar__message {
      font-size: 12px;
      width: 55%;
    }

    .cta-bar__actions {
      text-align: right;
      width: 45%;
    }

    .cancel-link {
      font-size: 12px;
      margin-right: 15px;

      &:hover {
        cursor: pointer;
        color: $cinder-block;
      }
    }
  }

  .cta-bar:not(.whitelabel-header) {
    background-color: $plume;

    .cta-bar__message {
      color: $light-grey;
    }

    .cancel-link {
      color: $grey;
    }
  }
}

.m-modal-confirm-delegate__email,
.m-modal-confirm-delegate__name {
  display: inline-block;
}

.m-modal-confirm-delegate__name {
  width: 48%;
  margin-right: 2%;
}
.m-modal-confirm-delegate__email {
  width: 50%;
}

.is-mobile {
  .m-modal-confirm-delegate {
    .main-content {
      padding: 20px 30px 0 30px;
    }

    .cta-bar {
      background-color: $white;
    }
  }
}
