@import 'colors.scss';
@import 'variables';

.m-error-page {
  text-align: center;

  .m-error-page--header {
    padding: 30px 15px;
    background-color: $ashen;
    color: white;
    font-weight: 300;

    .m-error-page--header--title {
      font-size: 25px;
      display: block;
      padding-bottom: 20px;

      & > i {
        display: block;
        font-size: 35px;
        padding-bottom: 20px;
      }
    }
  }

  &.is-network,
  &.is-no-valid-signature-types {
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
    z-index: 9999;
    height: 100vh;
    padding-top: 100px;

    .m-error-page--icon {
      float: left;
      width: 100%;
      height: 50px;
      background-image: url(./connectivity_icon@2x.png);
      background-size: 36px;
      background-repeat: no-repeat;
      background-position: 50%;
      margin-bottom: 20px;
    }

    .m-error-page--title {
      float: left;
      width: 100%;
      font-size: 18px;
      font-weight: var(--type__body__standard--fontweight_strong);
      color: $ashen;
      margin-bottom: 7px;
    }

    .m-error-page--message {
      float: left;
      width: 80%;
      margin: 0 10%;
    }

    .m-error-page--loader {
      width: 70px;
      height: 50px;
      top: 20px;
      left: 50%;
      position: relative;
      @include translate2d(-50%);
    }
  }

  &.is-network {
    .m-error-page--icon {
      background-image: url(./connectivity_icon@2x.png);
    }
  }

  &.is-no-valid-signature-types {
    .m-error-page--icon {
      background-image: url(./alert_icon_@2x.png);
    }
  }
}

.is-desktop {
  .m-error-page {
    background-color: $tepid-sloth;
  }
}
