.anchor {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.footer {
  pointer-events: auto;
  margin: 0 auto;
  max-width: var(--page--max-width);
  gap: var(--dig-spacing__micro__small);
}

.socials {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);

  .img {
    height: 32px;
  }
}
