:root {
  --signerColor-1: #8ae2f4;
  --signerColor-2: #f59600;
  --signerColor-3: #e5befc;
  --signerColor-4: #ffe175;
  --signerColor-5: #51d474;
  --signerColor-6: #c478ef;
  --signerColor-7: #d2f7d4;
  --signerColor-8: #b81414;
  --signerColor-9: #d7f9ff;
  --signerColor-10: #fff6c7;
  --signerColor-11: #10b3e4;
  --signerColor-12: #ffc733;
  --signerColor-13: #9844cd;
  --signerColor-14: #94eca0;
  --signerColor-15: #ffd6d6;
  --signerColor-16: #04dcdc;
  --signerColor-17: #ff9191;
  --signerColor-18: #20c09a;
  --signerColor-19: #3d7cb7;
  --signerColor-20: #71a600;
  --signerColor-sender: #ff54a8;
  --signerColor-preparer: transparent;
  --signerColor-disabled: transparent;
}

.handle {
  position: absolute;
  /* // Positioning is done with inline styles using a transform */
  top: 0;
  left: 0;

  z-index: 1;
}

.readOnly {
  /* This prevents the user from being able to interact with the field. It
  acts just like the field were stamped into the page. */
  pointer-events: none;
}

.documentPreview::before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* These are the old design's colors. They're never going to change and should
go away, so I have disconnected this code's dependency on the SASS variable
$signerColors */
.documentPreview.signer-1::before {
  border: 1px solid #8ae2f4;
}

.documentPreview.signer-2::before {
  border: 1px solid #f59600;
}

.documentPreview.signer-3::before {
  border: 1px solid #e5befc;
}

.documentPreview.signer-4::before {
  border: 1px solid #ffe175;
}

.documentPreview.signer-5::before {
  border: 1px solid #51d474;
}

.documentPreview.signer-6::before {
  border: 1px solid #c478ef;
}

.documentPreview.signer-7::before {
  border: 1px solid #d2f7d4;
}

.documentPreview.signer-8::before {
  border: 1px solid #b81414;
}

.documentPreview.signer-9::before {
  border: 1px solid #d7f9ff;
}

.documentPreview.signer-10::before {
  border: 1px solid #fff6c7;
}

.documentPreview.signer-11::before {
  border: 1px solid #10b3e4;
}

.documentPreview.signer-12::before {
  border: 1px solid #ffc733;
}

.documentPreview.signer-13::before {
  border: 1px solid #9844cd;
}

.documentPreview.signer-14::before {
  border: 1px solid #94eca0;
}

.documentPreview.signer-15::before {
  border: 1px solid #ffd6d6;
}

.documentPreview.signer-16::before {
  border: 1px solid #04dcdc;
}

.documentPreview.signer-17::before {
  border: 1px solid #ff9191;
}

.documentPreview.signer-18::before {
  border: 1px solid #20c09a;
}

.documentPreview.signer-19::before {
  border: 1px solid #3d7cb7;
}

.documentPreview.signer-20::before {
  border: 1px solid #71a600;
}

.documentPreview.signer-sender::before {
  border: 1px solid #ff54a8;
}

.documentPreview.signer-preparer::before {
  border: 1px solid #959ca4;
}

.documentPreview.signer-disabled::before {
  border: 1px solid #e9ecef;
}

.documentPreview.bd-signer-1::before {
  border: 1px solid var(--signerColor-1);
}

.documentPreview.bd-signer-2::before {
  border: 1px solid var(--signerColor-2);
}

.documentPreview.bd-signer-3::before {
  border: 1px solid var(--signerColor-3);
}

.documentPreview.bd-signer-4::before {
  border: 1px solid var(--signerColor-4);
}

.documentPreview.bd-signer-5::before {
  border: 1px solid var(--signerColor-5);
}

.documentPreview.bd-signer-6::before {
  border: 1px solid var(--signerColor-6);
}

.documentPreview.bd-signer-7::before {
  border: 1px solid var(--signerColor-7);
}

.documentPreview.bd-signer-8::before {
  border: 1px solid var(--signerColor-8);
}

.documentPreview.bd-signer-9::before {
  border: 1px solid var(--signerColor-9);
}

.documentPreview.bd-signer-10::before {
  border: 1px solid var(--signerColor-10);
}

.documentPreview.bd-signer-21::before {
  border: 1px solid var(--signerColor-21);
}

.documentPreview.bd-signer-22::before {
  border: 1px solid var(--signerColor-22);
}

.documentPreview.bd-signer-23::before {
  border: 1px solid var(--signerColor-23);
}

.documentPreview.bd-signer-24::before {
  border: 1px solid var(--signerColor-24);
}

.documentPreview.bd-signer-25::before {
  border: 1px solid var(--signerColor-25);
}

.documentPreview.bd-signer-26::before {
  border: 1px solid var(--signerColor-26);
}

.documentPreview.bd-signer-27::before {
  border: 1px solid var(--signerColor-27);
}

.documentPreview.bd-signer-28::before {
  border: 1px solid var(--signerColor-28);
}

.documentPreview.bd-signer-29::before {
  border: 1px solid var(--signerColor-29);
}

.documentPreview.bd-signer-20::before {
  border: 1px solid var(--signerColor-20);
}

.documentPreview.bd-signer-sender::before {
  border: 1px solid var(--signerColor-sender);
}

.documentPreview.bd-signer-preparer::before {
  border: 1px solid var(--signerColor-preparer);
}

.documentPreview.bd-signer-disabled::before {
  border: 1px solid var(--signerColor-disabled);
}

.documentPreview.signer-preparer::before {
  content: none;
}

.handle.selected {
  z-index: 99;
}

.handle:hover {
  z-index: 100;
}
