:global {
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .hs-debug-tools {
    position: fixed;
    z-index: 99999;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.5em !important;
  }

  .hs-debug-tools * {
    font-size: 14px;
    outline: none;
  }

  .hs-debug-tools--visible {
    display: block !important;
  }

  .hs-debug-tools__overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
  }

  .hs-debug-tools__wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px;
    padding-bottom: 30px;
    animation: 500ms slideUp 250ms cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: none;
  }

  .hs-debug-tools__treatment {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #d4d4d4;
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;
  }

  .hs-debug-tools__toolbar,
  .hs-debug-tools__panel {
    border-radius: 4px;
    background: white;
    box-shadow:
      0 0 0 1px rgba(99, 114, 130, 0.16),
      0 8px 16px rgba(27, 39, 51, 0.08);
  }

  .hs-debug-tools__panel {
    display: none;
    position: fixed;
    right: 10px;
    bottom: 60px;
    min-width: 300px;
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .hs-debug-tools__toolbar {
    background: white;
    height: 40px;
    position: relative;
    transition: left 0.25s ease-in 0.25s;
    pointer-events: auto;
    left: calc(100% - 10px);
  }

  .hs-debug-tools__toolbar.open {
    left: 0;
  }

  .hs-debug-tools__toolbar > ul {
    margin: 0;
  }

  .hs-debug-tools__toolbar > ul > li {
    display: inline-block;
  }

  .hs-debug-tools__toolbar > ul > li > button {
    display: inline-block;
    line-height: 32px;
    font-size: 32px;
    background: none;
    padding: 4px;
    margin: 0;
    color: black;
    height: 40px;
  }

  .hs-debug-tools__toolbar > ul > li > button.activated {
    box-shadow: 0 0 5px #ddd inset;
  }

  .hs-debug-tools__toolbar-toggle {
    border-right: 1px solid #e6e8eb;
  }

  .hs-debug-tools__toolbar-toggle svg {
    fill: #c1c7cd;
    transform: rotate(0deg);
    transition: transform 0.25s ease-in-out;
  }

  .hs-debug-tools__toolbar.open .hs-debug-tools__toolbar-toggle svg {
    transform: rotate(180deg);
  }

  .hs-debug-tools__toolbar-toggle:hover svg {
    fill: #637282;
  }

  .hs-debug-tools__toolbar
    > ul
    > li
    > button
    .devtools-launcher__icon
    .settings-cog {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-in-out;
  }

  .hs-debug-tools__toolbar
    > ul
    > li
    > button:hover
    .devtools-launcher__icon
    .settings-cog {
    transform: rotate(30deg);
  }

  @keyframes hs-debug-tools__anim-blink {
    0% {
      background: firebrick;
    }
    20% {
      background: red;
    }
    24% {
      background: firebrick;
    }
    28% {
      background: red;
    }
    32% {
      background: firebrick;
    }
  }

  .hs-debug-tools__toolbar-button-count {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 8px;
    min-width: 8px;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 8px;
    line-height: 8px;
    text-shadow: 1px 1px 0 black;
  }

  .hs-debug-tools__toolbar-button-count.red {
    animation: hs-debug-tools__anim-blink 5s step-end infinite;
    background: red;
  }

  .hs-debug-tools__toolbar-button-count.yellow {
    background: #f5bd17;
  }

  .hs-debug-tools__toolbar-button-count.green {
    background: #24b000;
  }

  .hs-debug-tools__panel .hs-debug-tools__section {
    padding: 16px;
  }

  .hs-debug-tools__panel .hs-debug-tools__section-header {
    margin: -16px -16px 16px -16px;
    background: #f7f9fa;
    padding: 12px 16px;
    border-top: 1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .hs-debug-tools__panel .hs-debug-tools__section-footer {
    margin: -16px -16px 16px -16px;
    margin-bottom: -16px;
    background: #f7f9fa;
    padding: 12px 16px;
    border-top: 1px solid #e6e8eb;
    border-bottom: 1px solid #e6e8eb;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }

  .hs-debug-tools__panel
    .hs-debug-tools__section:first-child
    .hs-debug-tools__section-header {
    border-top: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .hs-debug-tools__panel p {
    margin-bottom: 16px;
    color: #444;
    font-weight: 400;
  }

  .hs-debug-tools__panel .hs-debug-tools__section-header button {
    padding: 6px 12px;
    width: auto;
    line-height: 14px;
    height: auto;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .hs-debug-tools__panel .hs-debug-tools__section-header h2 {
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #536170;
  }

  .hs-debug-tools__panel-grid {
    display: grid;
    grid-row-gap: 0.25rem;
  }

  .hs-debug-tools__panel-grid > * {
    display: grid;
    grid-template-columns: 10rem 1fr;
    grid-column-gap: 0.5rem;
  }

  .hs-debug-tools__panel-grid > * > :first-child {
    color: black;
    font-weight: bold;
    text-align: right;
  }

  .hs-debug-tools__panel table th,
  .hs-debug-tools__panel table td {
    vertical-align: top;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .hs-debug-tools__panel table th {
    text-align: right;
    padding-right: 10px;
  }

  .hs-debug-tools__panel table th,
  .hs-debug-tools__panel table th label {
    font-weight: bold;
    color: black;
  }

  .hs-debug-tools__panel hr {
    display: block;
  }

  .hs-debug-tools__panel .hs-debug-tools__button,
  .hs-debug-tools__panel input.hs-debug-tools__button,
  .hs-debug-tools__panel select.hs-debug-tools__button {
    min-height: 38px;
    line-height: 18px;
    width: 100%;
    min-width: 140px;
    padding: 2px 15px;
    font-weight: 400;
    border: 1px solid #c1c7cd;
    border-radius: 4px;
    color: #1b2733;
    background-color: #fff;
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .hs-debug-tools__panel .hs-debug-tools__button:hover {
    border-color: #007af5;
    color: #007af5;
  }

  .hs-debug-tools__panel .hs-debug-tools__button:focus {
    box-shadow: 0 0 0 3px #a6bdeb;
  }

  .hs-debug-tools__panel .hs-debug-tools__button:disabled {
    color: #c1c7cd;
    border-color: #c1c7cd;
    background: white;
  }

  .hs-debug-tools__panel .hs-debug-tools__button--danger {
    border: 1px solid #cdc1c1;
    color: #331b1b;
  }

  .hs-debug-tools__panel .hs-debug-tools__button--danger:hover {
    border-color: #f50000;
    color: #f50000;
  }

  .hs-debug-tools__panel .hs-debug-tools__button--danger:focus {
    box-shadow: 0 0 0 3px #eba6a6;
  }

  .hs-debug-tools__panel
    .hs-debug-tools__section
    > .hs-debug-tools__button:last-child,
  .hs-debug-tools__panel tr:last-child .hs-debug-tools__button:last-child,
  .hs-debug-tools__panel a:last-child > .hs-debug-tools__button:last-child {
    margin-bottom: 0;
  }

  .hs-debug-tools__panel a:hover button {
    text-decoration: none;
  }

  .hs-debug-tools__git-revision {
    font-weight: 500;
    border-bottom: 1px dotted #c6cbd1;
    color: #1a1a1a;
  }

  .hs-debug-tools__git-revision:hover {
    border-bottom: 1px solid #1a1a1a;
  }

  .hs-debug-tools__adminapp-button {
    /* background-image: url(<?= image_path('adminapp/favicon.ico'); ?>); */
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 12px center;
  }

  .hs-debug-tools__panel
    .hs-debug-tools__section-header
    .hs-debug-tools__adminapp-button {
    padding-left: 28px;
    background-size: 18px;
    background-position: 6px center;
    min-height: auto;
    min-width: auto;
    font-size: 12px;
  }

  .hs-debug-tools__two-column-form {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 0.5rem;
  }

  .hs-debug-tools__log-table {
    display: block;
    max-height: 250px;
    overflow: auto;
    box-shadow: 0 0 10px #ddd inset;
    border-radius: 2px;
    border: 1px solid #ddd;
    padding: 4px;
  }

  .hs-debug-tools__log-table tr:nth-child(even) {
    background: #eee;
  }

  .hs-debug-tools__log-badge {
    display: inline-block;
    background: #ccc;
    border-radius: 4px;
    padding: 2px 4px;
    margin: 2px 4px 2px 0;
    font-size: 10px;
    font-weight: 600;
  }

  .hs-debug-tools__log-badge.red {
    background: #ffc1c1;
  }

  .hs-debug-tools__log-badge.yellow {
    background: #fdffc1;
  }

  .hs-debug-tools pre {
    font-family: monospace;
    font-size: 10px;
    padding: 4px;
  }

  .hs-debug-tools .throb {
    animation: throb 1s ease 0s infinite;
  }

  @keyframes throb {
    0% {
      box-shadow: 0 0 0 var(--color__accent__crimson);
    }
    50% {
      box-shadow: 0 0 10px var(--color__accent__crimson);
    }
    0% {
      box-shadow: 0 0 0 var(--color__accent__crimson);
    }
  }
}
