.inputContainer {
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--dig-color__border__base);
  & > .input {
    color: var(--dig-color__text__base);
    overflow: hidden;
    border: none;
    box-shadow: none;
    font-size: 44px !important;
    text-indent: 64px;
    position: absolute;
    inset: 0;
    box-shadow: none;
    padding: 64px 0 5px;
    margin: 0;

    &::placeholder {
      color: var(--dig-color__disabled__base);
    }

    &:focus {
      border: none;
      box-shadow: none;
      background-color: #fff;
    }
  }
}

.buttons {
  display: flex;
  justify-content: end;
  margin-top: var(--spacing__unit--1_5);
  & > .changeFontButton {
    padding: 0;
  }
}
