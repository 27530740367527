:global {
  .m-common-progress {
    max-width: 300px;
    width: 50%;
    fill: none;
    position: relative;
    stroke-width: 4px;
    & .signature-fill {
      stroke: var(--primary-blue);
      fill: none;
    }

    & .signature-fill-background {
      stroke: #cfcfcf;
    }

    .x-_____ {
      stroke-width: 3px !important;
    }

    & .message {
      position: absolute;
      color: #a0a0a0;
      top: 100px;
      left: 0px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.1em;
    }
  }
  #Layer_1 {
  }

  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    .m-common-progress {
      width: 200px;
    }
  }
}
