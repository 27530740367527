@import 'colors.scss';

.m-signer-mobile {
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  .m-login {
    margin-top: 10%;
  }
}

#signer-mobile-application {
  height: 100dvh;
  background-color: white;
}

.l-signer-mobile--desktop-footer {
  position: fixed;
  bottom: 20px;
  width: 75%;
  left: 50%;
  transform: translateX(-50%);
}

.is-desktop.is-site-ui {
  &.is-loading {
    background-color: $white;
  }

  &.is-loading.is-access-locked {
    background-color: $warm-chinchilla;
  }

  &.is-loaded {
    background-color: $warm-chinchilla;
  }
}

.is-desktop.is-embedded {
  padding-top: 0px;
  padding-bottom: 0px;

  .m-signer-mobile {
    width: 100%;
  }

  &.is-loading {
    background-color: $white;
  }

  &.is-loaded {
    background-color: $warm-chinchilla;
  }
}

.is-desktop {
  height: 100%;

  .m-signer-mobile {
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    left: 50%;
    position: relative;
  }

  .m-login--form {
    border: 1px solid $cinder-block;
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    position: relative;
    padding: 30px 10px;
  }

  .m-signature-document-page {
    transform-origin: left top 0px;
  }
}
