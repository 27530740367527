@import 'colors';

:local {
  .completeQESModal {
    :global(.hello-modal__title) {
      position: relative;
      background: $grey-025;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

      .dropdown {
        z-index: 999;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    :global(.hello-modal__body) {
      padding: 0;
      -webkit-overflow-scrolling: touch;
    }
  }

  .contentQES {
    max-width: 700px;
  }

  .iframeContentQES {
    width: 100%;
    height: 800px;
    border: none;
  }
}
