:root {
  --signer-color-1: #8ae2f4;
  --signer-color-2: #f59600;
  --signer-color-3: #e5befc;
  --signer-color-4: #ffe175;
  --signer-color-5: #51d474;
  --signer-color-6: #c478ef;
  --signer-color-7: #d2f7d4;
  --signer-color-8: #b81414;
  --signer-color-9: #d7f9ff;
  --signer-color-10: #fff6c7;
  --signer-color-11: #10b3e4;
  --signer-color-12: #ffc733;
  --signer-color-13: #9844cd;
  --signer-color-14: #94eca0;
  --signer-color-15: #ffd6d6;
  --signer-color-16: #04dcdc;
  --signer-color-17: #ff9191;
  --signer-color-18: #20c09a;
  --signer-color-19: #3d7cb7;
  --signer-color-20: #71a600;
  --signer-color-sender: #ff54a8;
  --signer-color-preparer: transparent;
  --signer-color-disabled: transparent;
}
