:root {
  --page--max-width: 1110px;
}

.header {
  max-width: var(--page--max-width);
  min-width: 0;
  width: max-content;
}

.whitespace {
  overflow: hidden;

  .whitespaceExpander {
    width: 100vw;
  }
}
