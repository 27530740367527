.doc {
  padding: var(--dig-spacing__micro__large);
  padding-bottom: var(--dig-spacing__macro__large);
  max-width: 700px;
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__large);
}

.signaturePad {
  height: 180px;
  font-family: var(--dig-type__family__text);
}

.hiddenTextField {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
