/* Extra caution taken here to ensure visual differences only applied with a
  * feature flag enabled. */
.containerV2,
.containerV2 .wrapper {
  height: 100%;
}

.container {
  /* This allows the container to shrink to fit its contents, and the
  `padding-right` on the `.button` is repected. */
  display: inline-block;
  /* Normally this should expand to 100% of the dropdown */
  width: 100%;
  vertical-align: top;
}

.wrapper {
  position: relative;
  width: auto;
  display: flex;

  & :global(.dig-ClickOutside) {
    flex-grow: 1;
  }
}

.digButton {
  padding: 0;
  margin: unset;

  & :global(.dig-StylelessButton-content) {
    font-family: var(--sign-font) !important;
    justify-content: space-between;
    padding-left: 4px;
  }
}

.container.hideControls {
  /* This allows the wrapper to shrink to fit its content */
  display: inline-block;
}
.container.hideControls .wrapper {
  /* This allows the wrapper to shrink to fit its content */
  display: inline-block;
}

.container.hideControls .button:after {
  visibility: hidden;
}

.container .wrapper {
  background-color: transparent;
  /* This helps with sizing for small fonts. */
  min-height: 0;
  color: black;
}
.container .wrapper .button {
  /* The editor/signer field provides its own border, so we don't need the
    * one built into the dropdown. */
  border-color: transparent;
  background-color: transparent;
  white-space: nowrap;
  min-height: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: inline;
}

/* but we have a hidden dropdown where this needs to shrink to fit the size of
  * its content. */
[aria-hidden] .container {
  width: auto;
}
