@font-face {
  font-family: 'Arial';
  src: url('./fonts/arial.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri.ttf') format('truetype');
}

@font-face {
  font-family: 'Cambria';
  src: url('./fonts/cambria.ttf') format('truetype');
}

@font-face {
  font-family: 'Courier';
  src: url('./fonts/cour.ttf') format('truetype');
}

@font-face {
  font-family: 'Georgia';
  src: url('./fonts/georgia.ttf') format('truetype');
}

@font-face {
  font-family: 'Times';
  src: url('./fonts/times.ttf') format('truetype');
}

@font-face {
  font-family: 'Trebuchet';
  src: url('./fonts/trebuc.ttf') format('truetype');
}

@font-face {
  font-family: 'Verdana';
  src: url('./fonts/verdana.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('./fonts/RobotoMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif';
  src: url('./fonts/NotoSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/NotoSans-Regular.ttf') format('truetype');
}
