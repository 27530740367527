.center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.page {
  page-break-after: always;
  position: relative;
  box-shadow: 0 3px 3px #eeeeee;
  background: #fcfcfc;
  height: 0;
}

.page.isBackdrop {
  box-shadow: var(--dig-utilities__boxshadow__raised);
  border: var(--dig-color__border__base);
  margin: 0 var(--spacing__unit--2);
}

.aspectContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Overlay mode doesn't show the image, so we don't want the shadow or background. */
.imgHidden {
  box-shadow: none;
  background: none;
}

.img {
  display: block;
  height: auto;
  position: relative;
}

.selectedComponents {
  border: 1px dotted black;
  position: absolute;
}

@media print {
  .page {
    margin-top: 0 !important;
  }
}
