@import 'colors';

.m-modal-confirm-decline {
  min-height: 379px;

  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 12px;
    height: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  .loader {
    position: relative;
    width: 25%;
    top: 200px;
    left: 38%;
  }

  .main-content {
    padding: 30px 75px 20px 75px;

    h1,
    h2 {
      font-weight: var(--type__body__standard--fontweight_strong);
      text-transform: uppercase;
    }

    h1 {
      font-size: 18px;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      margin-bottom: 0;

      .asterisk {
        margin-left: 2px;
        color: $red;
      }
    }

    small {
      font-size: 12px;
      word-spacing: 1px;
      color: $grey;
    }

    p {
      max-width: 425px;
      margin: 10px 0 20px 0;
    }

    textarea {
      height: 100px;
      margin-top: 10px;
      resize: none;
    }

    .error {
      color: $red;
      font-size: 14px;
    }
  }

  .cta-bar {
    min-height: 65px;
    background-color: $plume;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 16px;

    button {
      font-size: 14px;
      font-weight: var(--type__body__standard--fontweight_strong);
      margin: 0 4px;
    }
  }
}

.is-mobile {
  .m-modal-confirm-decline {
    .main-content {
      padding: 20px 30px 0 30px;
    }

    .cta-bar {
      background-color: $white;
    }
  }
}
