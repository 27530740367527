@media print {
  @page {
    margin: 0;
  }

  .modal {
    background: white;
    width: 100% !important;
    margin: 0;
    height: 100%;
    display: flex;
  }

  .modalFooter {
    display: none;
  }

  .closeButton {
    display: none;
  }
}
