@import 'variables';

.m-signature-request-status {
  width: 100%;
  height: 100%;

  .m-signature-request-status--logo {
    margin: 0px auto;
    max-width: 150px;
    width: 30%;
    display: block;
    @include translate2d(-50%);
    left: 50%;
    position: absolute;
    bottom: 40px;
  }

  .m-signature-request-status--small-message {
    display: none;
  }

  .m-common-progress {
    @include translate2d(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

@media screen and (max-width: 600px), screen and (max-height: 600px) {
  .m-signature-request-status {
    .m-common-progress {
      width: 200px;
      & .message {
        top: 67px;
        font-size: 10px;
      }
    }

    .m-signature-request-status--large-message {
      display: none;
    }

    .m-signature-request-status--small-message {
      display: inline-block;
    }
  }
}

@media screen and (max-height: 500px) {
  .m-signature-request-status {
    .m-signature-request-status--logo {
      width: 20%;
      bottom: 20px;
    }
  }
}
