.container {
  padding: var(--spacing__unit--5);
  background-color: #f7f5f2;
}

.form {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--spacing__unit--3);
  margin-bottom: var(--spacing__unit--2);
  width: 485px;
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.formRowContainer {
  width: 100%;
}
/* !important here because of higher specificity from DIG hover styles */
.submit {
  margin-top: 3px !important;
}

.submitWhenError {
  margin-top: 0 !important;
  margin-bottom: 21px !important;
}

.title {
  margin-top: 0;
}
