.input {
  display: none;
}

.checkbox {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  outline: none;
  cursor: auto;
}

.checked {
  background: url(../assets/checkmark.png) no-repeat;
  background-size: 100%;
}

/* Everybody loves fighting CSS specificity, right? */
input[type='radio'] + label.radioLabel,
.radioLabel svg.radio {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
}

@media print {
  .checked {
    content: url(../assets/checkmark.png);
    width: 100%;
  }
}
