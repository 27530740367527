/**
   * If we're using the default keyboard navigation behavior feature flag, then 
   * the 'active' and focused element may not necessary be the same element.  A
   * field may be 'active' but not focused. Because the active and focused 
   * styles look visually the same, we will prefer applying the 'active' styles 
   * based on the focused state going forward so we don't get into a scenario 
   * where a field looks focused when it actually is not.
   *
   * We're switching how we apply this style with the `data-active-style-version`
   * attribute, which is set via a feature flag.
   */

/* The focus indicator is handled by the `active` class, if using the original
  * style rules
  */
.signerField[data-active-style-version='1'] :focus {
  outline: none !important;
}

.groupBorder,
.fieldBorder {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  outline: 1px solid #a0a0a0;
}

.groupBorder.date,
.fieldBorder.date {
  display: none;
}

.groupBorder.group,
.fieldBorder.group {
  outline-style: dotted;
}

.groupBorder.radiobutton,
.fieldBorder.radiobutton {
  outline-color: transparent;
}

.groupBorder.active[data-active-style-version='1'],
.fieldBorder.active[data-active-style-version='1'] {
  outline: 1.5px solid var(--dig-color__primary__base);
}

.groupBorder.error[data-active-style-version='1'],
.fieldBorder.error[data-active-style-version='1'] {
  outline-color: red;
}

.groupBorder.required:after,
.fieldBorder.required:after {
  pointer-events: none;
  content: '*';

  color: #c9252c;
  position: absolute;
  right: 5px;
  top: 10%;
  height: 0;
  z-index: 1;
}

.groupBorder.radiobutton.required:after,
.groupBorder.checkbox.required:after,
.groupBorder.group.required:after,
.fieldBorder.radiobutton.required:after,
.fieldBorder.checkbox.required:after,
.fieldBorder.group.required:after {
  right: auto;
  left: -16px;
  top: 0;
}

.signerField[data-active-style-version='2'] .error {
  outline-color: var(--dig-color__alert__base);
}

/* For the v2 styles, continue to default outline styles since focus styles are
   * still rendered by a separate `.fieldBorder` element that's distinct from the
   * actual focused element. */
.signerField[data-active-style-version='2'] :focus:not([role='menuitem']) {
  outline: none;
}

/* If there is focus within a signer field, give the field border an outline */
.signerField[data-active-style-version='2']:focus-within .fieldBorder {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);

  /* Revert outline color if there is an error on this field. We only want
  the red outline when not focused */
  outline-color: #a0a0a0;
}

/* If a menu item has focus, don't put the focus ring on the field border
   This mimics how proper focus styles would be applied on the dropdown so
   that it doesn't look like two elements have focus at once.
   */
.signerField[data-active-style-version='2']:has([role='menuitem']:focus)
  .fieldBorder {
  outline-color: #a0a0a0;
  box-shadow: none;
}

/* Make the radio buttons have a round focus style to be more DIG like */
.signerField[data-active-style-version='2'] .radiobutton {
  border-radius: 50%;
}

.errorIndicator {
  color: var(--dig-color__alert__base);
  position: absolute;
  top: 0;
  left: calc(100% + var(--spacing__unit--0_5));
  display: flex;
  transform: scale(calc(var(--textScale) * 0.5));
  transform-origin: top left;
}
