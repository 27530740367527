:root {
  --signaturePlaceholderArrowSize: 10px;
  --signaturePlaceholderMenuColor: #1a1a1a;
}

.signaturePlaceholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  }
}

.displayField,
.signaturePlaceholder {
  cursor: pointer;
}

.overlayOpen {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalBase {
  position: fixed;
  left: 50%;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  min-width: 400px;
  background: rgb(255, 255, 255);
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a1a1a;
}

.menuPosition {
  padding: var(--signaturePlaceholderArrowSize);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.menuPosition.top {
  bottom: 100%;
}

.menuPosition.bottom {
  top: 100%;
}

.menu a {
  background-color: var(--signaturePlaceholderMenuColor);
  font-size: 16px;
  padding: 7px 14px;
  min-width: 45px;
  text-align: center;
  text-decoration: none;
  color: #f7f8f9;

  display: table-cell;
  white-space: nowrap;
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
  text-transform: capitalize;
}

.menu a:hover,
.menu a:focus {
  background-color: #a2bf00;
}

.menu a:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.menu a:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.arrow {
  border: solid transparent;
  content: ' ';
  height: 0;
  left: 50%;
  pointer-events: none;
  position: absolute;
  width: 0;

  border-width: var(--signaturePlaceholderArrowSize);
  margin-left: calc(0 - var(--signaturePlaceholderArrowSize));
}

.arrow.top {
  border-top-color: var(--signaturePlaceholderMenuColor);
  bottom: calc(0 - var(--signaturePlaceholderArrowSize));
}

.arrow.bottom {
  border-bottom-color: var(--signaturePlaceholderMenuColor);
  top: calc(0 - var(--signaturePlaceholderArrowSize));
}
