.confirm-qes__loader {
  width: 80px;
  height: 50px;
  top: 20px;
  left: 40%;
  position: relative;
}

.confirm-qes__description {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-align: center;
  padding: 20px 0px;
}

.confirm-qes__loader__div {
  min-block-size: 100px;
  min-height: 100px;
  -webkit-min-logical-height: 100px;
}

.confirm-qes__loader__img {
  block-size: 40px;
  display: inline-block;
  height: 40px;
  inline-size: 110px;
  margin: 30px 0px 42px 195px;
  max-inline-size: 100%;
  max-width: 100%;
  width: 110px;
}
