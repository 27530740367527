.UdSONG_loaderSpinner {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 44%;
  left: 44%;
}

.C7g3HG_document {
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--canvasColor, #e9ecef);
  width: 100%;
  padding-bottom: 50vh;
  position: relative;
  overflow: auto;
}

body.C7g3HG_blockScroll {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.C7g3HG_pageContainer {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.C7g3HG_noBackground {
  background: none;
}

@media print {
  .C7g3HG_document {
    -webkit-print-color-adjust: exact !important;
    background-color: #0000 !important;
  }
}

.pnA0wq_container {
  min-width: 220px;
}

.pnA0wq_footer {
  padding: var(--spacing__unit--1) 0;
  justify-content: flex-end;
  display: flex;
}

.kfGgwa_image {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.ZoR1OG_input {
  display: none;
}

.ZoR1OG_checkbox {
  cursor: auto;
  outline: none;
  position: absolute;
  inset: 1px;
}

.ZoR1OG_checked {
  background: url("checkmark.8bca1bda.png") 0 0 / 100% no-repeat;
}

input[type="radio"] + label.ZoR1OG_radioLabel, .ZoR1OG_radioLabel svg.ZoR1OG_radio {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
}

@media print {
  .ZoR1OG_checked {
    content: url("checkmark.8bca1bda.png");
    width: 100%;
  }
}

.visuallyHidden {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.nmscDq_containerV2, .nmscDq_containerV2 .nmscDq_wrapper {
  height: 100%;
}

.nmscDq_container {
  vertical-align: top;
  width: 100%;
  display: inline-block;
}

.nmscDq_wrapper {
  width: auto;
  display: flex;
  position: relative;
}

.nmscDq_wrapper .dig-ClickOutside {
  flex-grow: 1;
}

.nmscDq_digButton {
  margin: unset;
  padding: 0;
}

.nmscDq_digButton .dig-StylelessButton-content {
  justify-content: space-between;
  padding-left: 4px;
  font-family: var(--sign-font) !important;
}

.nmscDq_container.nmscDq_hideControls, .nmscDq_container.nmscDq_hideControls .nmscDq_wrapper {
  display: inline-block;
}

.nmscDq_container.nmscDq_hideControls .nmscDq_button:after {
  visibility: hidden;
}

.nmscDq_container .nmscDq_wrapper {
  color: #000;
  background-color: #0000;
  min-height: 0;
}

.nmscDq_container .nmscDq_wrapper .nmscDq_button {
  white-space: nowrap;
  background-color: #0000;
  border-color: #0000;
  min-height: 0;
  display: inline;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

[aria-hidden] .nmscDq_container {
  width: auto;
}

@font-face {
  font-family: Arial;
  src: url("arial.bdd3df66.ttf") format("truetype");
}

@font-face {
  font-family: Calibri;
  src: url("calibri.2137bc1e.ttf") format("truetype");
}

@font-face {
  font-family: Cambria;
  src: url("cambria.d96b35e3.ttf") format("truetype");
}

@font-face {
  font-family: Courier;
  src: url("cour.7b68fecb.ttf") format("truetype");
}

@font-face {
  font-family: Georgia;
  src: url("georgia.6e1853e4.ttf") format("truetype");
}

@font-face {
  font-family: Times;
  src: url("times.31ecff64.ttf") format("truetype");
}

@font-face {
  font-family: Trebuchet;
  src: url("trebuc.e009c9d5.ttf") format("truetype");
}

@font-face {
  font-family: Verdana;
  src: url("verdana.ae4fbe98.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.5068caf2.ttf") format("truetype");
}

@font-face {
  font-family: Roboto Mono;
  src: url("RobotoMono-Regular.93f5d6a8.ttf") format("truetype");
}

@font-face {
  font-family: Noto Serif;
  src: url("NotoSerif-Regular.f2322a95.ttf") format("truetype");
}

@font-face {
  font-family: Noto Sans;
  src: url("NotoSans-Regular.02fa86f5.ttf") format("truetype");
}

.gwp63a_font--helvetica {
  --sign-font: Helvetica, sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--arial {
  --sign-font: Arial, sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--calibri {
  --sign-font: "Calibri", serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--cambria {
  --sign-font: "Cambria", sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--courier {
  --sign-font: "Courier", serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--georgia {
  --sign-font: Georgia, serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--times {
  --sign-font: Times, serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--trebuchet {
  --sign-font: "Trebuchet", sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--verdana {
  --sign-font: "Verdana", sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--notoSerif {
  --sign-font: "Noto Serif", serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--notoSans {
  --sign-font: "Noto Sans", sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--roboto {
  --sign-font: "Roboto", sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_font--robotoMono {
  --sign-font: "Roboto Mono", sans-serif;
  font-family: var(--sign-font) !important;
}

.gwp63a_textarea {
  box-shadow: none;
  color: #000;
  resize: none;
  transform-origin: 0 0;
  background: none;
  border: none;
  outline: none;
  block-size: 16px;
  margin-top: .125em;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.gwp63a_dropdownText, .gwp63a_textarea {
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
  min-block-size: 1em;
  letter-spacing: 0;
  min-width: 3ch;
  min-height: 1em;
  inline-size: 100%;
  font-weight: 400;
  word-spacing: 0 !important;
  margin: 0 !important;
}

.gwp63a_textarea::placeholder {
  text-align: left;
  color: #a0a0a0;
}

.gwp63a_textarea[disabled], .gwp63a_textarea:focus, .gwp63a_textarea:read-only {
  background: none !important;
}

.UHcu0G_icon {
  width: 1.5em;
  height: 1.5em;
  margin: .5em;
}

.IEJyTG_hidden, .IEJyTG_placeholder {
  white-space: pre;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  min-height: 14px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.IEJyTG_hidden {
  visibility: hidden;
}

.IEJyTG_p {
  margin: 0;
  padding-right: .5em;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
  overflow: hidden;
}

._3KvRcq_hyperlink {
  cursor: pointer;
  text-align: left;
  color: #2b72d9;
  white-space: pre;
  background: #fff;
  text-decoration: underline;
  position: relative;
}

._3KvRcq_hyperlink:hover {
  color: #1c3a78;
  cursor: pointer;
}

._3KvRcq_hyperlink-textarea {
  cursor: auto;
  background: #fff !important;
}

._3KvRcq_hyperlink-textarea:focus {
  background: #fff !important;
}

@media print {
  ._3KvRcq_hyperlink {
    background: none;
  }
}

:root {
  --signerColor-1: #8ae2f4;
  --signerColor-2: #f59600;
  --signerColor-3: #e5befc;
  --signerColor-4: #ffe175;
  --signerColor-5: #51d474;
  --signerColor-6: #c478ef;
  --signerColor-7: #d2f7d4;
  --signerColor-8: #b81414;
  --signerColor-9: #d7f9ff;
  --signerColor-10: #fff6c7;
  --signerColor-11: #10b3e4;
  --signerColor-12: #ffc733;
  --signerColor-13: #9844cd;
  --signerColor-14: #94eca0;
  --signerColor-15: #ffd6d6;
  --signerColor-16: #04dcdc;
  --signerColor-17: #ff9191;
  --signerColor-18: #20c09a;
  --signerColor-19: #3d7cb7;
  --signerColor-20: #71a600;
  --signerColor-sender: #ff54a8;
  --signerColor-preparer: transparent;
  --signerColor-disabled: transparent;
}

.w-vW6a_handle {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.w-vW6a_readOnly {
  pointer-events: none;
}

.w-vW6a_documentPreview:before {
  content: " ";
  position: absolute;
  inset: 0;
}

.w-vW6a_documentPreview.w-vW6a_signer-1:before {
  border: 1px solid #8ae2f4;
}

.w-vW6a_documentPreview.w-vW6a_signer-2:before {
  border: 1px solid #f59600;
}

.w-vW6a_documentPreview.w-vW6a_signer-3:before {
  border: 1px solid #e5befc;
}

.w-vW6a_documentPreview.w-vW6a_signer-4:before {
  border: 1px solid #ffe175;
}

.w-vW6a_documentPreview.w-vW6a_signer-5:before {
  border: 1px solid #51d474;
}

.w-vW6a_documentPreview.w-vW6a_signer-6:before {
  border: 1px solid #c478ef;
}

.w-vW6a_documentPreview.w-vW6a_signer-7:before {
  border: 1px solid #d2f7d4;
}

.w-vW6a_documentPreview.w-vW6a_signer-8:before {
  border: 1px solid #b81414;
}

.w-vW6a_documentPreview.w-vW6a_signer-9:before {
  border: 1px solid #d7f9ff;
}

.w-vW6a_documentPreview.w-vW6a_signer-10:before {
  border: 1px solid #fff6c7;
}

.w-vW6a_documentPreview.w-vW6a_signer-11:before {
  border: 1px solid #10b3e4;
}

.w-vW6a_documentPreview.w-vW6a_signer-12:before {
  border: 1px solid #ffc733;
}

.w-vW6a_documentPreview.w-vW6a_signer-13:before {
  border: 1px solid #9844cd;
}

.w-vW6a_documentPreview.w-vW6a_signer-14:before {
  border: 1px solid #94eca0;
}

.w-vW6a_documentPreview.w-vW6a_signer-15:before {
  border: 1px solid #ffd6d6;
}

.w-vW6a_documentPreview.w-vW6a_signer-16:before {
  border: 1px solid #04dcdc;
}

.w-vW6a_documentPreview.w-vW6a_signer-17:before {
  border: 1px solid #ff9191;
}

.w-vW6a_documentPreview.w-vW6a_signer-18:before {
  border: 1px solid #20c09a;
}

.w-vW6a_documentPreview.w-vW6a_signer-19:before {
  border: 1px solid #3d7cb7;
}

.w-vW6a_documentPreview.w-vW6a_signer-20:before {
  border: 1px solid #71a600;
}

.w-vW6a_documentPreview.w-vW6a_signer-sender:before {
  border: 1px solid #ff54a8;
}

.w-vW6a_documentPreview.w-vW6a_signer-preparer:before {
  border: 1px solid #959ca4;
}

.w-vW6a_documentPreview.w-vW6a_signer-disabled:before {
  border: 1px solid #e9ecef;
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-1:before {
  border: 1px solid var(--signerColor-1);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-2:before {
  border: 1px solid var(--signerColor-2);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-3:before {
  border: 1px solid var(--signerColor-3);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-4:before {
  border: 1px solid var(--signerColor-4);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-5:before {
  border: 1px solid var(--signerColor-5);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-6:before {
  border: 1px solid var(--signerColor-6);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-7:before {
  border: 1px solid var(--signerColor-7);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-8:before {
  border: 1px solid var(--signerColor-8);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-9:before {
  border: 1px solid var(--signerColor-9);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-10:before {
  border: 1px solid var(--signerColor-10);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-21:before {
  border: 1px solid var(--signerColor-21);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-22:before {
  border: 1px solid var(--signerColor-22);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-23:before {
  border: 1px solid var(--signerColor-23);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-24:before {
  border: 1px solid var(--signerColor-24);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-25:before {
  border: 1px solid var(--signerColor-25);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-26:before {
  border: 1px solid var(--signerColor-26);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-27:before {
  border: 1px solid var(--signerColor-27);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-28:before {
  border: 1px solid var(--signerColor-28);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-29:before {
  border: 1px solid var(--signerColor-29);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-20:before {
  border: 1px solid var(--signerColor-20);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-sender:before {
  border: 1px solid var(--signerColor-sender);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-preparer:before {
  border: 1px solid var(--signerColor-preparer);
}

.w-vW6a_documentPreview.w-vW6a_bd-signer-disabled:before {
  border: 1px solid var(--signerColor-disabled);
}

.w-vW6a_documentPreview.w-vW6a_signer-preparer:before {
  content: none;
}

.w-vW6a_handle.w-vW6a_selected {
  z-index: 99;
}

.w-vW6a_handle:hover {
  z-index: 100;
}

.cYZ-wW_center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.cYZ-wW_page {
  page-break-after: always;
  background: #fcfcfc;
  height: 0;
  position: relative;
  box-shadow: 0 3px 3px #eee;
}

.cYZ-wW_page.cYZ-wW_isBackdrop {
  box-shadow: var(--dig-utilities__boxshadow__raised);
  border: var(--dig-color__border__base);
  margin: 0 var(--spacing__unit--2);
}

.cYZ-wW_aspectContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cYZ-wW_imgHidden {
  box-shadow: none;
  background: none;
}

.cYZ-wW_img {
  height: auto;
  display: block;
  position: relative;
}

.cYZ-wW_selectedComponents {
  border: 1px dotted #000;
  position: absolute;
}

@media print {
  .cYZ-wW_page {
    margin-top: 0 !important;
  }
}

p.fZwtPa_text {
  margin: 0;
}

p.fZwtPa_text + .fZwtPa_text {
  margin-top: var(--spacing__unit--3);
}

.sPblEG_signerField[data-active-style-version="1"] :focus {
  outline: none !important;
}

.sPblEG_groupBorder, .sPblEG_fieldBorder {
  pointer-events: none;
  outline: 1px solid #a0a0a0;
  position: absolute;
  inset: 0;
}

.sPblEG_groupBorder.sPblEG_date, .sPblEG_fieldBorder.sPblEG_date {
  display: none;
}

.sPblEG_groupBorder.sPblEG_group, .sPblEG_fieldBorder.sPblEG_group {
  outline-style: dotted;
}

.sPblEG_groupBorder.sPblEG_radiobutton, .sPblEG_fieldBorder.sPblEG_radiobutton {
  outline-color: #0000;
}

.sPblEG_groupBorder.sPblEG_active[data-active-style-version="1"], .sPblEG_fieldBorder.sPblEG_active[data-active-style-version="1"] {
  outline: 1.5px solid var(--dig-color__primary__base);
}

.sPblEG_groupBorder.sPblEG_error[data-active-style-version="1"], .sPblEG_fieldBorder.sPblEG_error[data-active-style-version="1"] {
  outline-color: red;
}

.sPblEG_groupBorder.sPblEG_required:after, .sPblEG_fieldBorder.sPblEG_required:after {
  pointer-events: none;
  content: "*";
  color: #c9252c;
  z-index: 1;
  height: 0;
  position: absolute;
  top: 10%;
  right: 5px;
}

.sPblEG_groupBorder.sPblEG_radiobutton.sPblEG_required:after, .sPblEG_groupBorder.sPblEG_checkbox.sPblEG_required:after, .sPblEG_groupBorder.sPblEG_group.sPblEG_required:after, .sPblEG_fieldBorder.sPblEG_radiobutton.sPblEG_required:after, .sPblEG_fieldBorder.sPblEG_checkbox.sPblEG_required:after, .sPblEG_fieldBorder.sPblEG_group.sPblEG_required:after {
  top: 0;
  left: -16px;
  right: auto;
}

.sPblEG_signerField[data-active-style-version="2"] .sPblEG_error {
  outline-color: var(--dig-color__alert__base);
}

.sPblEG_signerField[data-active-style-version="2"] :focus:not([role="menuitem"]) {
  outline: none;
}

.sPblEG_signerField[data-active-style-version="2"]:focus-within .sPblEG_fieldBorder {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  outline-color: #a0a0a0;
}

.sPblEG_signerField[data-active-style-version="2"]:has([role="menuitem"]:focus) .sPblEG_fieldBorder {
  box-shadow: none;
  outline-color: #a0a0a0;
}

.sPblEG_signerField[data-active-style-version="2"] .sPblEG_radiobutton {
  border-radius: 50%;
}

.sPblEG_errorIndicator {
  color: var(--dig-color__alert__base);
  top: 0;
  left: calc(100% + var(--spacing__unit--0_5));
  transform: scale(calc(var(--textScale) * .5));
  transform-origin: 0 0;
  display: flex;
  position: absolute;
}

._2Hzy1W_loading {
  flex-grow: 1;
  padding: 20px;
}

._2Hzy1W_panel {
  padding-top: 3px;
}

._2Hzy1W_panelContent {
  padding: var(--spacing__unit--3) 3px;
  height: 283px;
  margin: -3px;
  overflow-y: auto;
}

@media only screen and (width <= 320px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  ._2Hzy1W_modalBody {
    padding-left: var(--spacing__base_unit);
    padding-right: var(--spacing__base_unit);
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter {
    padding: var(--spacing__unit--0_5);
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_disclosure {
    margin-bottom: 0;
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_insertButton {
    margin-top: var(--spacing__unit--0_5);
  }

  .dig-Modal.dig-Modal--fullScreen ._2Hzy1W_modalHeader._2Hzy1W_modalHeader {
    padding-top: var(--spacing__unit--0_5);
    padding-bottom: var(--spacing__base_unit);
  }
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  ._2Hzy1W_modalBody {
    scroll-padding-top: calc(var(--spacing__unit--5)  + var(--spacing__unit--3));
    scroll-padding-bottom: var(--spacing__unit--3);
    flex-basis: 0;
    overflow-y: auto;
  }

  ._2Hzy1W_panelContent {
    height: unset;
  }

  ._2Hzy1W_tabs {
    z-index: 2;
    background: var(--dig-color__background__base);
    margin-top: -3px;
    padding-top: 3px;
    position: sticky;
    top: 0;
  }

  ._2Hzy1W_tabs > .dig-Tabs-selector {
    margin-top: 3px;
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter {
    border-top: 1px solid var(--dig-color__border__subtle);
  }
}

@media only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  ._2Hzy1W_tabs {
    position: relative;
  }
}

._2Hzy1W_modalFooter._2Hzy1W_modalFooter {
  justify-content: space-between;
  display: flex;
}

._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_buttonGroup {
  column-gap: var(--dig-spacing__micro__medium);
}

._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_insertButton {
  flex-shrink: 0;
}

@media only screen and (width <= 480px) {
  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter {
    flex-direction: column;
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_disclosure {
    margin-bottom: var(--spacing__base_unit);
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_buttonGroup {
    flex-direction: column;
    width: 100%;
  }

  ._2Hzy1W_modalFooter._2Hzy1W_modalFooter ._2Hzy1W_insertButton {
    margin-top: var(--spacing__base_unit);
    width: 100%;
    margin-left: 0;
  }
}

.n-M0bG_tabIconContainer {
  justify-content: center;
  align-items: center;
  gap: var(--spacing__unit--1);
  display: flex;
}

.kNDUSW_draw {
  width: 100%;
}

.kNDUSW_canvasContainer {
  border: 1px solid var(--dig-color__border__base);
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.kNDUSW_canvas {
  z-index: 2;
  display: block;
  position: relative;
}

.kNDUSW_buttons {
  margin-top: var(--spacing__unit--1_5);
  justify-content: end;
  align-items: center;
  display: flex;
}

.kNDUSW_buttons > .kNDUSW_button {
  padding: 0;
}

.Zp6tCG_buttonsDivider {
  margin-left: var(--spacing__unit--2);
  margin-right: var(--spacing__unit--2);
  background: var(--dig-color__border__base);
  vertical-align: middle;
  width: 1px;
  height: 28px;
  display: inline-block;
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  .Zp6tCG_buttonsDivider {
    margin-left: var(--spacing__base_unit);
    margin-right: var(--spacing__base_unit);
  }
}

.ggmudW_signatureModalLine {
  z-index: 0;
  border-bottom: 1px solid var(--dig-color__secondary__base);
  left: var(--spacing__unit--3);
  right: var(--spacing__unit--3);
  bottom: var(--spacing__unit--4);
  position: absolute;
}

.ggmudW_signatureModalLine:before, .ggmudW_signatureModalLine:after {
  content: "";
  left: var(--spacing__unit--2);
  bottom: var(--spacing__base_unit);
  height: var(--spacing__unit--2);
  background-color: var(--dig-color__text__base);
  width: 1.5px;
  position: absolute;
}

.ggmudW_signatureModalLine:before {
  transform: rotateZ(45deg);
}

.ggmudW_signatureModalLine:after {
  transform: rotateZ(-45deg);
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  .ggmudW_signatureModalLine {
    left: var(--spacing__unit--2);
    right: var(--spacing__unit--2);
  }
}

@media only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  .ggmudW_signatureModalLine {
    bottom: var(--spacing__unit--2);
  }
}

.BPe0Ca_inputContainer {
  border: 1px solid var(--dig-color__border__base);
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.BPe0Ca_inputContainer > .BPe0Ca_input {
  color: var(--dig-color__text__base);
  box-shadow: none;
  text-indent: 64px;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 64px 0 5px;
  position: absolute;
  inset: 0;
  overflow: hidden;
  font-size: 44px !important;
}

.BPe0Ca_inputContainer > .BPe0Ca_input::placeholder {
  color: var(--dig-color__disabled__base);
}

.BPe0Ca_inputContainer > .BPe0Ca_input:focus {
  box-shadow: none;
  background-color: #fff;
  border: none;
}

.BPe0Ca_buttons {
  margin-top: var(--spacing__unit--1_5);
  justify-content: end;
  display: flex;
}

.BPe0Ca_buttons > .BPe0Ca_changeFontButton {
  padding: 0;
}

.i6XxTa_ariaLive {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.showAria .i6XxTa_ariaLive {
  clip: unset;
  background: green;
  width: 100%;
  height: auto;
  font-size: 20px;
  top: 0;
  left: 0;
}

.showAria .i6XxTa_ariaLive > div {
  margin: 8px;
}

._4C9igG_dropzone {
  border: 2px dashed var(--dig-color__border__base);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 232px;
  display: flex;
}

._4C9igG_dropzone > ._4C9igG_uploadButton {
  margin-top: var(--spacing__unit--3);
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  ._4C9igG_dropzone {
    text-align: center;
    padding: var(--spacing__unit--2);
  }
}

.EuFQ0q_editArea {
  flex-direction: column;
  display: flex;
}

.EuFQ0q_previewImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.EuFQ0q_buttons {
  margin-top: var(--spacing__unit--1_5);
  justify-content: end;
  align-items: center;
  display: flex;
}

.EuFQ0q_buttons > .EuFQ0q_textButton {
  padding: 0;
}

.EuFQ0q_contrastLabel, .EuFQ0q_decreaseContrast {
  margin-right: var(--spacing__base_unit);
}

.EuFQ0q_contrastValue {
  margin-right: var(--spacing__base_unit);
  width: var(--spacing__unit--4);
  text-align: center;
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  .EuFQ0q_contrastLabel, .EuFQ0q_decreaseContrast, .EuFQ0q_contrastValue {
    margin-right: var(--spacing__unit--0_5);
  }
}

.qWPdOa_croppablePreview {
  border: 1px solid var(--dig-color__border__base);
  justify-content: center;
  align-items: center;
  height: 180px;
  display: flex;
}

.DTTtua_uploadProgressBar {
  padding-left: var(--spacing__unit--5);
  padding-right: var(--spacing__unit--5);
  margin-bottom: var(--spacing__unit--2);
  width: 100%;
}

.Xo8Y2W_noSignatureContainer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.Xo8Y2W_noSignatureContainer svg {
  width: 200px;
  height: 200px;
}

.Xo8Y2W_noSignatureContainer p {
  margin: 0;
}

.Xo8Y2W_signatureGrid {
  width: 100%;
  margin: 0;
}

.Xo8Y2W_signatureGrid tr {
  gap: var(--spacing__unit--2);
  margin-bottom: var(--spacing__unit--2);
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.Xo8Y2W_signatureGridCell {
  cursor: pointer;
  border: 1px solid var(--dig-color__border__subtle);
  outline: none;
  height: 120px;
  padding: 2px;
  scroll-margin: 30px 8px 8px;
}

.Xo8Y2W_signatureGridCell.Xo8Y2W_selected {
  border-width: 3px;
  border-color: var(--dig-color__secondary__base);
  padding: 0;
}

.Xo8Y2W_signatureGridCell:focus[data-focus-visible-added] {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
}

.Xo8Y2W_signatureGridCell:hover:not(.Xo8Y2W_selected) {
  background-color: var(--dig-color__opacity__surface);
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  .Xo8Y2W_signatureGridCell {
    height: 100px;
  }

  .Xo8Y2W_signatureGrid tr {
    gap: var(--spacing__unit--1);
    margin-bottom: var(--spacing__unit--1);
    grid-template-columns: 100%;
  }
}

._3E1eDW_savedSignature {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  position: relative;
}

._3E1eDW_savedSignatureInner {
  padding: var(--spacing__unit--3);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._3E1eDW_savedSignatureImage {
  opacity: .5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  transition: opacity .35s;
}

._3E1eDW_savedSignature._3E1eDW_selected ._3E1eDW_savedSignatureImage, ._3E1eDW_savedSignature:hover ._3E1eDW_savedSignatureImage {
  opacity: 1;
}

._3E1eDW_savedSignatureDelete._3E1eDW_savedSignatureDelete {
  pointer-events: none;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

._3E1eDW_savedSignature._3E1eDW_selected ._3E1eDW_savedSignatureDelete._3E1eDW_savedSignatureDelete, ._3E1eDW_savedSignature:hover ._3E1eDW_savedSignatureDelete._3E1eDW_savedSignatureDelete {
  pointer-events: all;
  display: block;
}

@media only screen and (width <= 767px), only screen and (width <= 900px) and (aspect-ratio >= 3 / 2) {
  ._3E1eDW_savedSignatureInner {
    padding: var(--spacing__unit--2_5);
  }
}

:root {
  --signaturePlaceholderArrowSize: 10px;
  --signaturePlaceholderMenuColor: #1a1a1a;
}

.xk9WrG_signaturePlaceholder {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.xk9WrG_signaturePlaceholder:focus {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  outline: none;
}

.xk9WrG_displayField, .xk9WrG_signaturePlaceholder {
  cursor: pointer;
}

.xk9WrG_overlayOpen {
  z-index: 1000;
  background-color: #00000080;
  position: fixed;
  inset: 0;
}

.xk9WrG_modalBase {
  letter-spacing: normal;
  color: #1a1a1a;
  background: #fff;
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-family: proxima-nova, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.5;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.xk9WrG_menuPosition {
  padding: var(--signaturePlaceholderArrowSize);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.xk9WrG_menuPosition.xk9WrG_top {
  bottom: 100%;
}

.xk9WrG_menuPosition.xk9WrG_bottom {
  top: 100%;
}

.xk9WrG_menu a {
  background-color: var(--signaturePlaceholderMenuColor);
  text-align: center;
  color: #f7f8f9;
  white-space: nowrap;
  text-transform: capitalize;
  border-right: 1px solid #ffffff80;
  min-width: 45px;
  padding: 7px 14px;
  font-size: 16px;
  text-decoration: none;
  display: table-cell;
}

.xk9WrG_menu a:hover, .xk9WrG_menu a:focus {
  background-color: #a2bf00;
}

.xk9WrG_menu a:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.xk9WrG_menu a:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.xk9WrG_arrow {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  border-width: var(--signaturePlaceholderArrowSize);
  margin-left: calc(0 - var(--signaturePlaceholderArrowSize));
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
}

.xk9WrG_arrow.xk9WrG_top {
  border-top-color: var(--signaturePlaceholderMenuColor);
  bottom: calc(0 - var(--signaturePlaceholderArrowSize));
}

.xk9WrG_arrow.xk9WrG_bottom {
  border-bottom-color: var(--signaturePlaceholderMenuColor);
  top: calc(0 - var(--signaturePlaceholderArrowSize));
}

.d1lZ4a_documentContainer {
  max-height: 100%;
  position: relative;
  overflow: auto;
}

.d1lZ4a_container {
  flex-direction: column;
  height: 100dvh;
  display: flex;
}

.qx9ljq_nextButton {
  margin-left: auto;
}

.N6_lOG_alert {
  color: var(--dig-color__alert__base);
}

.hjPfoa_customLogo {
  max-height: 40px;
}

.hjPfoa_poweredByLogo {
  max-width: 100px;
}

.hjPfoa_toolbarSeparator {
  height: 1px;
  margin-bottom: -1px;
  position: relative;
}

.hjPfoa_reviewToolbar {
  gap: var(--dig-spacing__macro__small);
}

.hjPfoa_reviewToolbarTitle {
  margin-top: 0;
}

._56c4jq_focusRing:focus {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
}

._56c4jq_focusRing:focus:not([data-focus-visible-added]) {
  box-shadow: none;
}

.SJUw3W_formRow {
  align-items: start;
}

.SJUw3W_formLabel {
  height: auto;
  display: inline;
}

@media print {
  @page {
    margin: 0;
  }

  .nMpbJG_modal {
    background: #fff;
    height: 100%;
    margin: 0;
    display: flex;
    width: 100% !important;
  }

  .nMpbJG_modalFooter, .nMpbJG_closeButton {
    display: none;
  }
}

:root {
  --page--max-width: 1110px;
}

.Lr-riG_header {
  max-width: var(--page--max-width);
  width: max-content;
  min-width: 0;
}

.Lr-riG_whitespace {
  overflow: hidden;
}

.Lr-riG_whitespace .Lr-riG_whitespaceExpander {
  width: 100vw;
}

.DDSYbG_anchor {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.DDSYbG_footer {
  pointer-events: auto;
  max-width: var(--page--max-width);
  gap: var(--dig-spacing__micro__small);
  margin: 0 auto;
}

.DDSYbG_socials {
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
  flex-direction: row;
  margin-left: auto;
  display: flex;
}

.DDSYbG_socials .DDSYbG_img {
  height: 32px;
}

.tvjzUG_logo.tvjzUG_logo {
  color: #000;
  height: 32px;
}

.tvjzUG_logo.tvjzUG_logo.tvjzUG_tagline {
  height: 40px;
}

.tvjzUG_logo:focus-within svg {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  outline: 0;
  text-decoration: none;
}

.tvjzUG_logoLink {
  display: block;
}

.q4pdpG_container {
  flex-direction: column;
  display: flex;
}

.q4pdpG_header {
  z-index: 1;
  width: 100%;
  position: sticky;
  top: 0;
}

.q4pdpG_header .dig-GlobalHeader {
  width: 100%;
}

.q4pdpG_logo.q4pdpG_isFax svg {
  vertical-align: middle;
  max-height: 32px;
}

.q4pdpG_content {
  padding: var(--spacing__unit--1);
  width: 100%;
  min-width: 320px;
  display: flex;
}

.q4pdpG_content.q4pdpG_centered {
  width: 100%;
  max-width: 840px;
  margin: 64px auto 48px;
}

.q4pdpG_content.q4pdpG_centered.q4pdpG_v900 {
  margin: var(--spacing__unit--5) auto;
  max-width: 900px;
}

@media (width <= 1024px) {
  .q4pdpG_content.q4pdpG_centered {
    padding: 0 var(--spacing__unit--2);
    max-width: 424px;
    margin-top: 48px;
  }

  .q4pdpG_content.q4pdpG_centered.q4pdpG_v900 {
    margin-top: var(--spacing__unit--5);
  }
}

@media (width <= 768px) {
  .q4pdpG_content.q4pdpG_centered {
    max-width: 396px;
    margin-top: 32px;
  }

  .q4pdpG_content.q4pdpG_centered.q4pdpG_v900 {
    margin-top: var(--spacing__unit--3);
  }
}

.q4pdpG_contentInner {
  width: 100%;
}

.qDvuFa_title {
  margin-top: 0;
}

.qDvuFa_mb1 {
  margin-bottom: var(--spacing__unit--1);
}

.qDvuFa_marginBottom {
  margin-bottom: var(--spacing__unit--3);
}

.qDvuFa_illustrationContainer {
  margin-bottom: var(--spacing__unit--5);
  width: 200px;
  height: 200px;
}

.qDvuFa_center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.gYNTRa_container {
  padding: var(--spacing__unit--5);
  background-color: #f7f5f2;
}

.gYNTRa_form {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--spacing__unit--3);
  margin-bottom: var(--spacing__unit--2);
  align-items: center;
  gap: var(--spacing__unit--1);
  width: 485px;
  display: flex;
}

.gYNTRa_formRowContainer {
  width: 100%;
}

.gYNTRa_submit {
  margin-top: 3px !important;
}

.gYNTRa_submitWhenError {
  margin-top: 0 !important;
  margin-bottom: 21px !important;
}

.gYNTRa_title {
  margin-top: 0;
}

.RNyejG_container {
  text-align: center;
  max-width: 30rem;
  margin: auto;
}

.vXqFiW_doc {
  padding: var(--dig-spacing__micro__large);
  padding-bottom: var(--dig-spacing__macro__large);
  gap: var(--dig-spacing__micro__large);
  flex-direction: column;
  max-width: 700px;
  margin-inline: auto;
  display: flex;
}

.vXqFiW_signaturePad {
  font-family: var(--dig-type__family__text);
  height: 180px;
}

.vXqFiW_hiddenTextField {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

._2a9aEa_circle {
  fill: var(--dig-color__primary-cta__base);
  fill-opacity: .4;
  transition: all .5s;
}

._2a9aEa_circle[data-state="active"] {
  fill-opacity: 1;
}

._2a9aEa_fullPageLoader {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

._2a9aEa_fullPageLoader > * {
  width: 150px;
}

:root {
  --signer-color-1: #8ae2f4;
  --signer-color-2: #f59600;
  --signer-color-3: #e5befc;
  --signer-color-4: #ffe175;
  --signer-color-5: #51d474;
  --signer-color-6: #c478ef;
  --signer-color-7: #d2f7d4;
  --signer-color-8: #b81414;
  --signer-color-9: #d7f9ff;
  --signer-color-10: #fff6c7;
  --signer-color-11: #10b3e4;
  --signer-color-12: #ffc733;
  --signer-color-13: #9844cd;
  --signer-color-14: #94eca0;
  --signer-color-15: #ffd6d6;
  --signer-color-16: #04dcdc;
  --signer-color-17: #ff9191;
  --signer-color-18: #20c09a;
  --signer-color-19: #3d7cb7;
  --signer-color-20: #71a600;
  --signer-color-sender: #ff54a8;
  --signer-color-preparer: transparent;
  --signer-color-disabled: transparent;
}

.UdXNVG_notificationBanners {
  margin-bottom: var(--spacing__unit--4);
}

.UdXNVG_notificationBanner {
  margin-bottom: var(--spacing__unit--1);
}

.UdXNVG_notificationBanner.UdXNVG_isAnimated {
  transition: opacity .15s;
  animation: .35s UdXNVG_slideIn;
}

.UdXNVG_notificationBanner.UdXNVG_isAnimated.UdXNVG_isFading {
  opacity: 0;
  transition-duration: 2.5s;
}

@keyframes UdXNVG_slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}
/*# sourceMappingURL=index.css.map */
