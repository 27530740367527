.font--helvetica {
  --sign-font: Helvetica, sans-serif;
  font-family: var(--sign-font) !important;
}
.font--arial {
  --sign-font: Arial, sans-serif;
  font-family: var(--sign-font) !important;
}
.font--calibri {
  --sign-font: 'Calibri', serif;
  font-family: var(--sign-font) !important;
}
.font--cambria {
  --sign-font: 'Cambria', sans-serif;
  font-family: var(--sign-font) !important;
}
.font--courier {
  --sign-font: 'Courier', serif;
  font-family: var(--sign-font) !important;
}
.font--georgia {
  --sign-font: Georgia, serif;
  font-family: var(--sign-font) !important;
}
.font--times {
  --sign-font: Times, serif;
  font-family: var(--sign-font) !important;
}
.font--trebuchet {
  --sign-font: 'Trebuchet', sans-serif;
  font-family: var(--sign-font) !important;
}
.font--verdana {
  --sign-font: 'Verdana', sans-serif;
  font-family: var(--sign-font) !important;
}
.font--notoSerif {
  --sign-font: 'Noto Serif', serif;
  font-family: var(--sign-font) !important;
}
.font--notoSans {
  --sign-font: 'Noto Sans', sans-serif;
  font-family: var(--sign-font) !important;
}
.font--roboto {
  --sign-font: 'Roboto', sans-serif;
  font-family: var(--sign-font) !important;
}
.font--robotoMono {
  --sign-font: 'Roboto Mono', sans-serif;
  font-family: var(--sign-font) !important;
}

.textarea {
  position: absolute;
  inset: 0;
  border: none;
  box-shadow: none;

  background: transparent;
  color: black;
  /* font-family: NimbusSansL, Helvetica, Arial, sans-serif !important;
  font-size: 24px !important; */
  overflow: hidden;
  padding: 0px;
  resize: none;
  transform-origin: top left;
  block-size: 16px;

  margin-top: 0.125em;
  outline: none;
}

/* Dropdowns share the same text/font related settings */
.dropdownText,
.textarea {
  min-block-size: 1em;
  min-height: 1em;
  min-width: 3ch; /* the width of `000` in the current font */

  white-space: pre;
  word-break: normal;
  word-wrap: normal;
  word-spacing: 0em !important;

  font-weight: 400;
  inline-size: 100%;
  letter-spacing: 0em;
  margin: 0 !important;
}

.textarea::placeholder {
  text-align: left;
  color: #a0a0a0; /* $light-grey; */
}

.textarea[disabled],
.textarea:focus,
.textarea:read-only {
  background: transparent !important;
}
