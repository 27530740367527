.customLogo {
  max-height: 40px;
}

.poweredByLogo {
  max-width: 100px;
}

.toolbarSeparator {
  height: 1px;
  /** Negative margin to overlap the toolbar below so the color bleeds through */
  margin-bottom: -1px;

  /**
   * To ensure this sits on top of the toolbar below
   */
  position: relative;
}

.reviewToolbar {
  gap: var(--dig-spacing__macro__small);
}

.reviewToolbarTitle {
  /**
   * DIG titles come with margin applied :(
   */
  margin-top: 0;
}
