@import 'colors.scss';

.m-signer-mobile-header {
  background-color: #1a1a1a;
  padding: 18px 30px;
  font-size: 15px;
  color: $plume;
  font-weight: 300;
  width: 100%;
  line-height: 1.4em;
  letter-spacing: 0.05em;
  z-index: 1024;
  position: relative;
  transition: 0.25s;
}
