.circle {
  fill: var(--dig-color__primary-cta__base);
  fill-opacity: 0.4;
  transition: ease 0.5s;
}

.circle[data-state='active'] {
  fill-opacity: 1;
}

.fullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullPageLoader > * {
  width: 150px;
}
