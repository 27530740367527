@import 'colors.scss';

.m-signer-mobile-template-link {
  .m-signer-mobile-logo {
    max-height: 40px;
    margin-bottom: 70px;
  }
}

.m-signer-mobile-template-link--doc-background {
  background: no-repeat;
  background-size: 100% 100%;
  background-image: url('./assets/doc_bg.png');
  width: 100%;
  height: 100%;
}

.m-signer-mobile-template-link--modal {
  max-width: 450px;
}

.is-desktop {
  .m-signer-mobile-template-link {
    .m-signer-mobile-logo {
      max-height: 40px;
      margin-bottom: 70px;
    }
  }

  .m-signer-mobile-template-link--modal {
    left: 50%;
    top: 10%;
    position: relative;
    transform: translateX(-50%);
  }
}

.in-person-instructions {
  margin: 10px 0;

  & > h3,
  & > h4,
  & > p,
  & > small {
    color: $white;
  }

  & > h4 {
    margin-bottom: 8px;
  }

  img {
    width: 20px;
    height: 20px;
    margin: 5px;
  }
}

#login_modal {
  margin-left: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  visibility: visible;
}

.m-login-in-person--ipad-wrapper {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}
