.m-signer-choice {
  .m-signer-choice--subheader {
    .m-signer-choice--subheader--message {
      margin: 20px 0;
    }

    .m-signer-choice--subheader--cta {
      width: 100%;
      text-align: right;
    }
  }

  .m-signer-choice--list {
    min-height: 250px;
    padding: 20px 40px;

    .m-signer-choice--list--choice {
      float: left;
      width: 100%;
      margin-bottom: 20px;

      .m-signer-choice--list--choice--radio {
        margin: 0;
      }

      .m-signer-choice--list--choice--label {
        font-weight: var(--type__body__standard--fontweight_strong);
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}
