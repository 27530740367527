/**
 * This file is a workaround for getting DIG-like focus styles without fully
 * implementing a DIG button. Use this if you need focus styles, but aren't
 * ready to refactor any further.
 */
.focusRing:focus {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
}

.focusRing:focus:not([data-focus-visible-added]) {
  box-shadow: none;
}
